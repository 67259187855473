import { graphql, Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import DesktopView from '../../components/Scoresheets/DesktopView'
import MobileView from '../../components/Scoresheets/MobileView'

import PageNavigationWrapper from '../../components/pagination/navWrapper'

const ScoresheetPageStyles = styled.div`
  p.description {
    text-align: center;

    a {
      text-decoration: underline;
    }
  }
  .mobile-version {
    display: block;
  }

  .desktop-version {
    display: none;
  }

  @media all and (min-width: 1136px) {
    .mobile-version {
      display: none;
    }

    .desktop-version {
      display: block;
    }
  }
`

export default function Scoresheets({ data, location }) {
  const { nodes: scoresheets, pageInfo } = data.scoresheets

  return (
    <ScoresheetPageStyles className="content-width">
      <h1 className="title">Past Scoresheets</h1>
      <p className="description">
        This page contains past scoresheets of the Pioneer District. To view
        other district or international scores, please visit{' '}
        <a
          href="http://www.harmonize.ws/HarmonetReporter/scores/scormain.htm"
          target="blank"
          rel="noopener noreferrer"
          className="dark"
        >
          The Harmonet Reporter
        </a>
      </p>
      <p className="description">
        For information on <strong>Plateaus</strong> and the current Plateau
        standings{' '}
        <Link className="dark" to="plateaus">
          CLICK HERE
        </Link>
      </p>
      <PageNavigationWrapper
        pageInfo={pageInfo}
        location={location}
        basePath="/history/scoresheets"
      />
      <div className="desktop-version">
        <DesktopView scoresheets={scoresheets} />
      </div>
      <div className="mobile-version">
        <MobileView scoresheets={scoresheets} />
      </div>
    </ScoresheetPageStyles>
  )
}

export const ScoresheetQuery = graphql`
  query ScoresheetQuery($skip: Int!, $limit: Int!) {
    scoresheets: allSanityScoresheets(
      sort: { fields: year, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      pageInfo {
        currentPage
        hasNextPage
        pageCount
        hasPreviousPage
      }
      nodes {
        _key
        id
        year
        chorusSheets {
          _key
          title
          File {
            asset {
              url
            }
          }
        }
        quartetSheets {
          _key
          title
          File {
            asset {
              url
            }
          }
        }
      }
    }
  }
`
