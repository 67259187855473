import React, { Component } from 'react'
import styled from 'styled-components'

const ScoresheetTableStyles = styled.div`
  overflow: scroll;
  font-size: 80%;
  text-align: center;
  margin-top: 25px;

  table {
    margin: 0 auto;
    max-width: 1100px;
    min-width: 80%;
    border-collapse: collapse;
  }

  table {
    border: 1px solid black;

    thead tr th {
      padding: 10px;
    }

    tbody tr td {
      padding: 0 5px;

      :nth-child(5) {
        padding: 0;
      }
    }

    td,
    th {
      border: 1px solid #ddd;
    }
  }

  tbody tr {
    pointer-events: auto;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tbody tr:hover {
    background-color: #eee;
  }

  table {
    thead {
      padding: 0;
      margin: 0;
      background-color: var(--link-hover);
      color: white;
    }
  }

  .group-info {
    /* text-align: left; */
    padding: 0;
    margin: 0;
    list-style: none;
  }
`

const ScoresheetListStyles = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1rem;
  padding: 20px;

  p {
    text-decoration: underline;
    font-style: italic;
    padding: 0;
    margin: 0;
  }
`

const generateList = arr => (
  <ScoresheetListStyles>
    {arr.map(item => {
      const { _key, title } = item
      const fileUrl = item.File.asset.url

      return (
        <p key={_key}>
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </p>
      )
    })}
  </ScoresheetListStyles>
)

class ScoresheetComponent extends Component {
  constructor({ scoresheets }) {
    super()
    this.scoresheets = scoresheets
    this.rows = this.createRows()
  }

  createRows() {
    const { scoresheets } = this

    return scoresheets.map(info => {
      const { chorusSheets, quartetSheets, year, id } = info
      let quartetList = <></>
      let chorusList = <></>

      if (quartetSheets.length !== 0) {
        quartetList = generateList(quartetSheets)
      }

      if (chorusSheets.length !== 0) {
        chorusList = generateList(chorusSheets)
      }

      return (
        <tr key={id}>
          <td>{year}</td>
          <td>{quartetList}</td>
          <td>{chorusList}</td>
        </tr>
      )
    })
  }

  render() {
    const { rows } = this

    return (
      <ScoresheetTableStyles>
        <table>
          <thead>
            <tr>
              <th>Year</th>
              <th>Quartet</th>
              <th>Chorus</th>
            </tr>
          </thead>
          <tbody>{rows.map(row => row)}</tbody>
        </table>
      </ScoresheetTableStyles>
    )
  }
}

export default function DesktopChamps({ scoresheets }) {
  return <ScoresheetComponent scoresheets={scoresheets} />
}
