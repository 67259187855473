import React from 'react'
import styled from 'styled-components'

const YearStyles = styled.div`
  text-align: center;

  .year {
    font-size: 5rem;
    text-decoration: underline;
    margin-bottom: 20px;
  }

  .entries {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* flex-wrap: wrap; */
    gap: 2rem;
  }

  .item-wrapper {
    width: 40%;
    min-width: 300px;
    margin: 20px auto;
    h2 {
      /* text-decoration: underline; */
    }
  }
`

const ScoresheetListStyles = styled.div`
  /* width: 40%; */
  /* min-width: 300px; */
  margin: 20px auto;
  margin-bottom: 30px;

  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;

  p {
    margin: 0;
    padding: 0;
    font-style: italic;
    text-decoration: underline;
  }
`

const generateList = arr => (
  <ScoresheetListStyles>
    {arr.map(item => {
      const { _key, title } = item
      const fileUrl = item.File.asset.url

      return (
        <p key={_key}>
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </p>
      )
    })}
  </ScoresheetListStyles>
)

export default function MobileScoresheets({ scoresheets }) {
  return scoresheets.map(info => {
    const { chorusSheets, quartetSheets, year, id } = info
    let quartetEl = <></>
    let chorusEl = <></>

    if (quartetSheets.length > 0) {
      const quartetList = generateList(quartetSheets)
      quartetEl = (
        <div className="item-wrapper">
          <h2 className="title">Quartet Scoresheets</h2>
          {quartetList}
        </div>
      )
    }

    if (chorusSheets.length > 0) {
      const chorusList = generateList(chorusSheets)
      chorusEl = (
        <div className="item-wrapper">
          <h2 className="title">Chorus Scoresheets</h2>
          {chorusList}
        </div>
      )
    }

    return (
      <YearStyles key={id}>
        <h2 className="year">{year}</h2>
        {quartetEl}
        {chorusEl}
      </YearStyles>
    )
  })
}
